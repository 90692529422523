import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useState } from "react";
import MobileNavigation from "./mobile-navigation";
import logo from "../../static/images/logo.svg";


const Navigation = ({navInverse }) => {

    const navigationQuery = useStaticQuery(graphql`
        query navigationQuery {
        whoWeAre: contentfulWhoWeArePage {
            heroBackgroundImage {
                fixed(width: 242, height: 142) {
                    ...GatsbyContentfulFixed_noBase64
                }
              }
        }
       solutionsAndServices: contentfulSolutionsServicesPage {
            heroBackgroundImage {
                fixed(width: 242, height: 142) {
                    ...GatsbyContentfulFixed_noBase64
                }
              }
        }
       commitments: contentfulCommitments {
            heroBackgroundImage {
                fixed(width: 242, height: 142) {
                    ...GatsbyContentfulFixed_noBase64
                }
           }
        }
       ourWork: contentfulOurWork {
            heroBackgroundImage {
                fixed(width: 242, height: 142) {
                    ...GatsbyContentfulFixed_noBase64
                }
          }
        }
       careers: contentfulCareers {
            heroBackgroundImage {
                fixed(width: 242, height: 142) {
                    ...GatsbyContentfulFixed_noBase64
                }
          }
        }
       navigation: allContentfulNavigation {
            edges {
                node  {
                    title
                    description {
                        description
                    }
                }
            }
        }
       site {
            siteMetadata {
                careers {
                    application_url
                }
             }
       }

      }
    `);


    const [isOpen, setMenuState] = useState(false);

    //this is for the text content in each dropdown
    const dropdown = {};
    dropdown.whoWeAre = navigationQuery.navigation.edges.filter(n => n.node.title == 'Who We Are')[0];
    dropdown.solutions = navigationQuery.navigation.edges.filter(n => n.node.title == 'Solutions & Services')[0];
    dropdown.commitments = navigationQuery.navigation.edges.filter(n => n.node.title == 'Sustainability')[0];
    dropdown.ourWork = navigationQuery.navigation.edges.filter(n => n.node.title == 'Our Work')[0];
    dropdown.careers = navigationQuery.navigation.edges.filter(n => n.node.title == 'Careers')[0];

    return (
        <>
            <div className={`flex px-4 pt-6 justify-between ${(navInverse || isOpen) && 'nav-inverse'}`}>

          {/*logo*/}
            <div className="logo pb-2 -mt-2">
            <Link to="/">
              <img src={logo} alt="Man Enterprise Logo" />
            </Link>
          </div>

          <div className="lg:hidden">
              {/*hamburger menu icon*/}
              <div className="-mr-2 -my-3 md:-my-1 ml-auto">
                <button
                    type="button"
                    className="mobile-menu-button inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-400 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-400 transition duration-150 ease-in-out"
                    onClick={() => setMenuState(!isOpen)} 
                 >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
          </div>

          {/* main navigation menu*/}
            <nav className="justify-between space-x-8 hidden lg:flex navigation-container">
              <div className="relative navigation-link bubble-arrow">
                  <Link
                      to="/who-we-are"
                      className="font-bold inline-flex items-center text-white focus:outline-none transition duration-150 ease-in-out"
                      activeClassName="navigation-active"
                      partiallyActive={true}
                      >
                       Who We Are
                       <svg className="h-5 w-5 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                  </Link>

                  <div className="duration-150 ease-in-out p-6 pb-8 bg-white absolute hidden dropdown">
                    <div className="w-1/2 border-r-2 pr-8">
                        <Img loading="eager" className="mr-auto w-full mb-2" fixed={navigationQuery.whoWeAre.heroBackgroundImage.fixed} />
                        <h3 className="dropdown__header text-xs mb-4 uppercase tracking-tight">Who We Are</h3>
                        <p className="text-sm">
                            {dropdown.whoWeAre.node.description.description}
                        </p>
                    </div>
                    <div className="w-1/2 pl-8">
                        <ul className="">
                            <li><Link to="/who-we-are/#the-group">The Group</Link></li>
                            <li><Link to="/who-we-are/#vision-mission">Vision &amp; Mission</Link></li>
                            <li><Link to="/who-we-are/#our-core-values">Our Core Values</Link></li>
                            <li><Link to="/who-we-are/#heritage-history">Heritage &amp; History</Link></li>
                            <li><Link to="/who-we-are/#our-people">Leadership</Link></li>
                        </ul>
                    </div>
                  </div>


              </div>

              <div className="relative navigation-link bubble-arrow">
                  <Link
                      to="/solutions-services"
                      className="font-bold inline-flex items-center text-white focus:outline-none transition duration-150 ease-in-out"
                      activeClassName="navigation-active"
                      partiallyActive={true}
                      >
                      Solutions &amp; Services
                       <svg className="h-5 w-5 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                  </Link>

                  <div className="duration-150 ease-in-out p-6 pb-8 bg-white absolute hidden dropdown">
                    <div className="w-1/2 border-r-2 pr-8">
                        <Img loading="eager" className="mr-auto w-full mb-2" fixed={navigationQuery.solutionsAndServices.heroBackgroundImage.fixed} />
                        <h3 className="dropdown__header text-xs mb-4 uppercase tracking-tight">Solutions &amp; Services</h3>
                        <p className="text-sm">
                            {dropdown.solutions.node.description.description}
                        </p>

                    </div>
                    <div className="w-1/2 pl-8">
                        <ul className="">
                            <li><Link activeClassName="navigation-active" partiallyActive={true} to="/solutions-services/engineering-construction">Engineering &amp; Construction</Link></li>
                            <li>
                                <a href="http://www.metrixmep.com" target="_blank">Electro-Mechanical
                                    <svg className="h-4 inline-block ml-2 -mt-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="http://www.tecmanindustry.com/" target="_blank">Manufacturing
                                    <svg className="h-4 inline-block ml-2 -mt-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://hausman.com/" target="_blank">O&amp;M - Integrated Facility Solutions
                                    <svg className="h-4 inline-block ml-2 -mt-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.latoscanaimpianti.it/en/" target="_blank">Oil &amp; Gas
                                    <svg className="h-4 inline-block ml-2 -mt-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                    </svg>
                                </a>
                            </li>
                            <li><Link activeClassName="" partiallyActive={false} to="/solutions-services/">Renewable Energy</Link></li>
                            <li><Link activeClassName="" partiallyActive={false} to="/solutions-services/">Waste Management</Link></li>
                        </ul>
                    </div>
                  </div>

              </div>

              <div className="relative navigation-link bubble-arrow">
                  <Link
                      to="/commitments"
                      className="no-dropdown font-bold inline-flex items-center text-white focus:outline-none transition duration-150 ease-in-out"
                      activeClassName="navigation-active"
                      >
                      ESG
                      <svg className="h-5 w-5 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                  </Link>

                  <div className="duration-150 ease-in-out p-6 pb-8 bg-white absolute hidden dropdown">
                    <div className="w-1/2 border-r-2 pr-8">
                        <Img loading="eager" className="mr-auto w-full mb-2" fixed={navigationQuery.commitments.heroBackgroundImage.fixed} />
                        <h3 className="dropdown__header text-xs mb-4 uppercase tracking-tight">ESG</h3>
                        <p className="text-sm">{dropdown.commitments.node.description.description}</p>
                            
                    </div>
                    <div className="w-1/2 pl-8">
                        <ul className="">
                            <li><Link to="/commitments#corporategovernance">Corporate Governance</Link></li>
                            <li><Link to="/commitments#safety&welfare">Safety &amp; Welfare</Link></li>
                            <li><Link to="/commitments#quality">Quality</Link></li>
                            <li><Link to="/commitments#sustainability">Sustainability</Link></li>
                            <li><Link to="/commitments#leanconstruction:themanway">Lean Construction: The MAN Way</Link></li>
                            <li><Link to="/commitments#developingcareers">Developing Careers</Link></li>
                            <li><Link to="/commitments#diversity">Diversity</Link></li>
                        </ul>
                    </div>
                  </div>

              </div>

              <div className="relative navigation-link bubble-arrow">
                  <Link
                      to="/our-work"
                      className="no-dropdown font-bold inline-flex items-center text-white focus:outline-none transition duration-150 ease-in-out"
                      activeClassName="navigation-active"
                      partiallyActive={true}
                      >
                      Our Work
                      <svg className="h-5 w-5 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                  </Link>

                <div className="duration-150 ease-in-out p-6 pb-8 bg-white absolute hidden dropdown dropdown-right">
                    <div className="w-1/2 border-r-2 pr-8">
                        <Img loading="eager" className="mr-auto w-full mb-2" fixed={navigationQuery.ourWork.heroBackgroundImage.fixed} />
                        <h3 className="dropdown__header text-xs mb-4 uppercase tracking-tight">Our Work</h3>
                        <p className="text-sm">{dropdown.ourWork.node.description.description}</p>
                    </div>
                    <div className="w-1/2 pl-8">
                        <ul className="">
                            <li><Link to="/our-work" state={{"preset":"Commercial"}}>Commercial</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Education"}}>Education</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Governmental"}}>Governmental</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Healthcare"}}>Healthcare</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Hospitality"}}>Hospitality</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Industrial"}}>Industrial</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Infrastructure & Transport"}}>Infrastructure &amp; Transport</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Offices"}}>Offices</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Cultural & Recreational"}}>Cultural &amp; Recreational</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Residential"}}>Residential</Link></li>
                        </ul>
                    </div>
                  </div>

              </div>

              <div className="relative navigation-link bubble-arrow">
                  <Link
                      to="/careers"
                      className="no-dropdown font-bold inline-flex items-center text-white focus:outline-none transition duration-150 ease-in-out"
                      activeClassName="navigation-active"
                      >
                      Careers
                      <svg className="h-5 w-5 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                  </Link>

                <div className="duration-150 ease-in-out p-6 pb-8 bg-white absolute hidden dropdown dropdown-right">
                    <div className="w-1/2 border-r-2 pr-8">
                        <Img loading="eager" className="mr-auto w-full mb-2" fixed={navigationQuery.careers.heroBackgroundImage.fixed} />
                        <h3 className="dropdown__header text-xs mb-4 uppercase tracking-tight">Careers</h3>
                        <p className="text-sm">{dropdown.careers.node.description.description}</p>
                    </div>
                    <div className="w-1/2 pl-8">
                        <ul className="">
                            <li><Link to="/careers#why-man-group">Why MAN Enterprise Group</Link></li>
                            <li><Link to="/careers#our-resources">Our Resources</Link></li>
                            <li><Link to="/careers#working-at-man-enterprise">Working at MAN Enterprise</Link></li>
                            <li>
                                <Link className="inline-flex" to="/careers/professionals">
                                    <svg className="w-4 h-4 mt-1 dark-blue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                    Professionals
                                </Link>
                            </li>
                            <li>
                                <Link className="inline-flex" to="/careers/students-recent-grads">
                                    <svg className="w-4 h-4 mt-1 dark-blue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                    Recent Grads
                                </Link>
                            </li>
                            <li>
                            <Link className="inline-flex" to="/careers/skilled-craft">
                                    <svg className="w-4 h-4 mt-1 dark-blue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                    Skilled Craft
                            </Link>
                            </li>
                            <li>
                            <Link className="inline-flex" to="/careers/students-recent-grads">
                                    <svg className="w-4 h-4 mt-1 dark-blue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                    Students
                            </Link>

                            </li>
                            <li>
                                <Link target="_blank" to={navigationQuery.site.siteMetadata.careers.application_url}>
                                Find your role
                                </Link>
                            </li>

                        </ul>
                    </div>
                  </div>

              </div>

              <div className="relative navigation-link">
                  <Link
                      to="/locations"
                      className="no-dropdown font-bold inline-flex items-center text-white focus:outline-none transition duration-150 ease-in-out"
                      activeClassName="navigation-active"
                      >
                      Contact
                  </Link>
              </div>

          </nav>
        </div>
        
        { isOpen && <MobileNavigation displayState={isOpen} /> }
    </>
  );

    
}


export default Navigation
