import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types";
import React from "react";

const Footer = () => {
    const data = useStaticQuery(graphql`
        query footerQuery {
              site {
                  siteMetadata {
                     links {
                            facebook
                            instagram
                            youtube
                            twitter
                            linkedin
                          }
                      }
              }
              facebookIcon: contentfulAsset(title: {eq: "social-icon-facebook"}) {
                        file {
                            url
                        }
              }
              twitterIcon: contentfulAsset(title: {eq: "social-icon-twitter"}) {
                        file {
                            url
                        }
              }
              linkedinIcon: contentfulAsset(title: {eq: "social-icon-linkedin"}) {
                        file {
                            url
                        }
              }
              youtubeIcon: contentfulAsset(title: {eq: "social-icon-youtube"}) {
                        file {
                            url
                        }
              }
              instagramIcon: contentfulAsset(title: {eq: "social-icon-instagram"}) {
                        file {
                            url
                        }
              }



      }
    `)

    return (
    <div className="footer p-8 border-t" style={{backgroundColor : '#f5f5f5'}}>
        <div className="container mx-auto sm:flex border-b">

            <div className="flex flex-1 mr-4 mb-8">
                <ul className="flex-1 mr-4">
                    <li className="tracking-wide dark-blue font-acumin mb-4"><Link to="/become-a-partner">Become a Partner</Link></li>
                    <li className="tracking-wide dark-blue font-acumin"><Link to="/locations">Locations</Link></li>
                </ul>

                <ul className="flex-1">
                    <li className="tracking-wide dark-blue font-acumin mb-4"><Link to="/newsletter">Publications</Link></li>
                    <li className="tracking-wide dark-blue font-acumin"><Link to="/careers">Careers</Link></li>
                </ul>
            </div>

            <div className="flex-1 mr-12 mb-8">
                <h3 className="font-bold dark-blue font-aktiv text-xl mb-0">Follow Us</h3>
                <ul>
                    <li className="tracking-normal font-acumin gray">See the latest updates from MAN Enterprise</li>
                </ul>
                <div className="social-icons mt-8">
                    <ul className="flex">
                        <SocialMediaLink iconUrl={data.facebookIcon.file.url} link={data.site.siteMetadata.links.facebook} />
                        <SocialMediaLink iconUrl={data.twitterIcon.file.url} link={data.site.siteMetadata.links.twitter}/>
                        <SocialMediaLink iconUrl={data.instagramIcon.file.url} link={data.site.siteMetadata.links.instagram}/>
                        <SocialMediaLink iconUrl={data.linkedinIcon.file.url} link={data.site.siteMetadata.links.linkedin}/>
                        <SocialMediaLink iconUrl={data.youtubeIcon.file.url} link={data.site.siteMetadata.links.youtube}/>
                    </ul>
                    </div>
            </div>

            <div className="flex-1">
                <h3 className="font-bold dark-blue font-aktiv text-xl mb-0">Newsletter</h3>
                <ul className="mb-4">
                    <li className="tracking-normal font-acumin gray">Sign up for news and updates from our projects</li>
                </ul>
                <div className="input-with-icon relative">
                        <form action="https://manenterprise.us6.list-manage.com/subscribe/post?u=e7a377fc35b2d5c34240d7f4e&amp;id=e46dc00f60" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                        <input className="border soft-gray p-2 w-full focus:outline-none focus:border-mane-yellow"
                           placeholder="Email address"
                           type="email"
                           name="EMAIL"
                           id="mce-EMAIL"
                           required
                        /> 

                        <div style={{"position": "absolute", "left" : "-5000px"}} ariaHidden="true">
                            <input type="text" name="b_e7a377fc35b2d5c34240d7f4e_e46dc00f60" tabindex="-1" value={''} />
                        </div>

                        <NewsletterSubscribeButton />
                    </form>
                </div>

            </div>
        </div>

        <div className="container mx-auto lg:flex pt-8">

            <div className="sm:mr-auto">
                <ul className="flex justify-center mb-2">
                    <li><a target="_blank" href="http://www.metrixmep.com/"><img className="h-3 sm:h-5 mr-4 mb-2 w-24" src={`/images/logos/mlogo.svg`} /></a></li>
                    <li><a target="_blank" href="http://www.tecmanindustry.com/"><img className="h-3 sm:h-5 mr-6 mb-2 w-24"  src={`/images/logos/tlogo.svg`} /></a></li>
                    <li><a target="_blank" href="https://www.latoscanaimpianti.it/en/"><img className="h-3 sm:h-5 mr-6 mb-2 w-24" src={`/images/logos/ltilogo.svg`} /></a></li>
                    <li><a target="_blank" href=""><img className="h-6 sm:h-8 h-6 sm:w-8 -mt-1 mr-2" src={`/images/logos/mondis.png`} /></a></li>
                    <li><a target="_blank" href="https://hausman.com/"><img className="h-8 sm:h-10 w-24 -mt-2" src={`/images/logos/hlogo.svg`} /></a></li>
                </ul>
            </div>
            <div className="ml-auto">
                <ul className="flex">
                    <li className="text-xs sm:text-sm sm:border-r border-blue-700 pr-2 sm:px-6 dark-blue font-acumin"><Link to={`/privacy-policy`}>Privacy Policy</Link></li>
                    <li className="text-xs sm:text-sm sm:border-r border-blue-700 pr-2 sm:px-6 dark-blue font-acumin"><Link to={`/terms-conditions`}>Terms &amp; Conditions</Link></li>
                    <li className="text-xs sm:text-sm sm:border-r border-blue-700 pr-2 sm:px-6 dark-blue font-acumin">Site Map</li>
                    <li className="text-xs sm:text-sm pl-2 sm:pl-6 light-gray font-acumin">© {new Date().getFullYear() } MAN Enterprise. All rights reserved.</li>
                </ul>
            </div>




        </div>
    </div>

    )
}

const SocialMediaLink = ({link, iconUrl}) => {

    return (
        <li className="mr-4"><a target="_blank" href={link}><img className="h-6" src={iconUrl} /></a></li>
    )
}

const NewsletterSubscribeButton = () => {
    const val = '';

    return (
        <input className="input-field-email-icon submit-button bg-no-repeat cursor-pointer" value={val} type="submit" />
    )
}

export default Footer
