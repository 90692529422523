import React from "react";
import PropTypes from "prop-types";
import Header from "../components/header";
import Hero from "../components/hero";
import Footer from "../components/footer";
import "../styles/styles.css";

const Layout = ({ children, hero}) => {

    return (
    <>
        <Header />
        <Hero hero={hero} />
        {children}
        <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
