import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types";
import React, { useState } from "react";
import Navigation from "./navigation";
import { Link as ScrollLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

const Hero = ({hero}) => {

    let backgroundImage;
    let vimeoId;
    let bottomFeature;

    if (hero.heroBackgroundImage && hero.heroBackgroundImage.resize) {
        backgroundImage = hero.heroBackgroundImage.resize.src;
    } else if (hero.heroBackgroundImageFallback && hero.heroBackgroundImageFallback.resize) { 
        backgroundImage = hero.heroBackgroundImageFallback.resize.src;
    } else {
        backgroundImage = '/Homepage_Banner@1x.jpg';
    }

    if (hero.home) { 
        backgroundImage = '';
        vimeoId = hero.backgroundVideo.replace(/\D/g, ''); //get id from vimeo link
    }

    return (
        <div className={`hero relative mb-16 overflow-hidden pb-12 pt-4 sm:py-20 ${hero.bottomFeature && 'sm:pt-20 sm:pb-0'} bg-center bg-cover bg-no-repeat ${hero.home && 'home-video-bg'}`}
            style={{ backgroundImage: `url(${backgroundImage})` }}>
            {hero.home && 
            <div className="vimeo-wrapper">
                <iframe
                    className="video"
                    src={`https://player.vimeo.com/video/${vimeoId}?background=1&autoplay=1&loop=1&byline=0&title=0`}  
                >
                </iframe>
            </div>
            }
                
            <div className="container mx-auto">
                <div className="z-10 pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">

                    <div className="container mt-10 mx-auto px-4 mt-24 xl:mt-28 bg-cover">
                        <div className="mt-8 md:mt-16 xl:mt-32 lg:text-left w-4/5">
                            {hero.subsectionName && <p className="text-white leading-normal font-aktiv font-bold mb-2 sm:leading-relaxed uppercase lg:w-3/5">{hero.subsectionName}</p>}
                            {hero.heroHeading && hero.heroHeading.childMarkdownRemark
                            ?
                            <div className={hero.smallerTextHeading ? `home-heading text-3-5` : `home-heading`}
                               dangerouslySetInnerHTML={{ __html: hero.heroHeading.childMarkdownRemark.html }}
                            />                    
                            :
                             <div className={hero.smallerTextHeading ? `home-heading text-3-5` : `home-heading`}>                    
                                 {hero.heroHeading}
                             </div>
                            }
                            {hero.heroText && hero.heroText.childMarkdownRemark
                            ?
                                <div className={`nested-html text-white leading-normal sm:leading-relaxed sm:text-2xl text-shadow ${hero.home && 'lg:w-3/5'}`} 
                               dangerouslySetInnerHTML={{ __html: hero.heroText.childMarkdownRemark.html }}/>                    
                            :
                            <div className="nested-html text-white leading-normal sm:leading-relaxed sm:text-2xl text-shadow">
                                {hero.heroText}
                            </div>
                            }
                            {hero.heroHeadingTwo && hero.heroHeadingTwo.childMarkdownRemark
                            ?
                            <div className="nested-html text-white leading-normal sm:leading-relaxed sm:text-2xl lg:w-3/5 text-shadow" 
                               dangerouslySetInnerHTML={{ __html: hero.heroHeadingTwo.childMarkdownRemark.html }}
                            />                    
                            :
                            <div className="mt-4 nested-html text-white leading-normal sm:leading-relaxed sm:text-2xl lg:w-3/5 text-shadow">
                                {hero.heroHeadingTwo}
                            </div>
                            }
                            {hero.buttons}
                        </div>
                    </div>

                {hero.home && 
                <div className="scroll-below">
                    <ScrollBelow />
                </div>
                }
 
                </div>
            </div>
            {hero.bottomFeature}
        </div>
    )
}

const ScrollBelow = () => {

    const [showButton, toggleButton] = useState(true);

    const scrollClick = () => {
        scroll.scrollTo(document.documentElement.clientHeight,
            {
                smooth: 'easeInOutQuad'
            });

        toggleButton(false);
       
    }

    return showButton && (
        <ScrollLink> 
        <button onClick={() => scrollClick() } className="text-white text-default font-bold uppercase">
        Scroll
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff" className="h-10 mx-auto bounce">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
        </button>
        </ScrollLink>
    );
};

export default Hero
