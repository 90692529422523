import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Navigation from "./navigation";


const Header = () => {

    const [hasScrolledBelow, setScrolledBelow] = useState(false);

    //make nav bg white when scrolling down
    useEffect(() => {

        const onScroll = () => {
            let currentPosition = window.pageYOffset;
            currentPosition > 80 ? setScrolledBelow(true) : setScrolledBelow(false);
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])


    return (
        <div className={`header fixed z-50 w-full ${hasScrolledBelow && 'nav-inverse'}`}>
            <div className="container mx-auto">
                <Navigation navInverse={hasScrolledBelow} />
            </div>
        </div>
    );
}

export default Header
